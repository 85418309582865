import axiosInstance from "./authAxios";

const authService = {
    refreshToken: async () => {
        try {
            const oldRefreshToken = localStorage.getItem("refreshToken")
            const response = await axiosInstance.post('/api/refreshToken', { refreshToken: oldRefreshToken })
            const { accessToken, refreshToken } = response.data

            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)

            return true
        } catch(error) {
            console.error("Error refreshing token: ", error)
            window.location.href='/#/auth/signin'
            return false
        }
    }
}

export default authService